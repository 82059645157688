import React from 'react'

import Layout from '../component/layout'
import Link from '../component/link'
import Image from '../component/image'
import JourneyOmnidya from '../component/journeyOmnidya'
import PageTitle from '../component/typography/pageTitle'
import SubTitle from '../component/typography/subtitle'
import Title from '../component/typography/title'

const Contact = () => {
  return(
    <Layout page="contact-header-bg" title="Contact Us" captions="Join the fastest growing car insurance company in the U.S.">
      <div className="container-xxl">
        <div className="row justify-content-center text-center mb-5">
          <div className="col col-md-6">
            <PageTitle title="Contact Omnidya" className="py-5" />
            <p>Get in touch and let us know how we can help.</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col col-lg-8">
            <div className="row pb-5">
              <div className="col-xs col-sm-5">
                <div className="nav flex-column nav-pills custom-list font-book" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <Link className="nav-link active" id="general-tab" data-toggle="pill" href="#general" role="tab" aria-controls="general" aria-selected="true">
                    <div className="icon mr-sm-2">
                      <Image src="vehicle_shape.svg" className="svgIcon" />
                    </div>
                    General Inquiries
                  </Link>
                  <Link className="nav-link" id="technicalHelp-tab" data-toggle="pill" href="#technicalHelp" role="tab" aria-controls="technicalHelp" aria-selected="false">
                    <div className="icon mr-sm-2">
                      <Image src="download-2.svg" className="svgIcon" />
                    </div>
                    Technical Help
                  </Link>
                  <Link className="nav-link" id="press-tab" data-toggle="pill" href="#press" role="tab" aria-controls="press" aria-selected="false">
                    <div className="icon mr-sm-2">
                      <Image src="bookmark_shape.svg" className="svgIcon" />
                    </div>
                    Press
                  </Link>
                  <Link className="nav-link" id="otherInquiries-tab" data-toggle="pill" href="#otherInquiries" role="tab" aria-controls="otherInquiries" aria-selected="false">
                    <div className="icon mr-sm-2">
                      <Image src="insurance_shape.svg" className="svgIcon" />
                    </div>
                    Other Inquiries
                  </Link>
                </div>
              </div>
              <div className="col-xs col-sm-7">
                <div className="tab-content" id="v-pills-tabContent">
                  <div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
                    <SubTitle title="Write Us" className="mb-2" />
                    <form action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
                        <input type="hidden" name="orgid" value="00D4W000005ikyp" />
                        <input type="hidden" name="retURL" value="https://www.omnidya.com/" />
                        <input type="hidden" name="type" value="General Inquiry" />
                        <div className="form-group">
                          <label htmlFor="name">Name</label>
                          <input id="name" name="name" size="20" type="text" className="form-control"  />
                        </div>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input id="email" name="email" type="text" className="form-control"  />
                        </div>
                        <div className="form-group">
                          <label htmlFor="description">Message Us</label>
                          <textarea name="description" id="description" className="form-control" rows="3" ></textarea>
                        </div>
                        <button type="submit" name="submit" className="btn btn-blue btn-block">Submit</button>
                      </form>
                  </div>
                  <div className="tab-pane fade" id="technicalHelp" role="tabpanel" aria-labelledby="technicalHelp-tab">
                    <SubTitle title="Write Us" className="mb-2" />
                    <form action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
                      <input type="hidden" name="orgid" value="00D4W000005ikyp" />
                      <input type="hidden" name="retURL" value="https://www.omnidya.com/" />
                      <input type="hidden" name="type" value="Technical Query"/>
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input id="name" name="name" size="20" type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input id="email" name="email" type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="description">Message Us</label>
                        <textarea name="description" id="description" className="form-control" rows="3" ></textarea>
                      </div>
                      <button type="submit" name="submit" className="btn btn-blue btn-block">Submit</button>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="press" role="tabpanel" aria-labelledby="press-tab">
                    <SubTitle title="Write Us" className="mb-2" />
                    <form action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
                      <input type="hidden" name="orgid" value="00D4W000005ikyp" />
                      <input type="hidden" name="retURL" value="https://www.omnidya.com/" />
                      <input type="hidden" name="type" value="Press"/>
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input id="name" name="name" size="20" type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input id="email" name="email" type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="description">Message Us</label>
                        <textarea name="description" id="description" className="form-control" rows="3" ></textarea>
                      </div>
                      <button type="submit" name="submit" className="btn btn-blue btn-block">Submit</button>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="otherInquiries" role="tabpanel" aria-labelledby="otherInquiries-tab">
                    <SubTitle title="Write Us" className="mb-2" />
                    <form action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
                      <input type="hidden" name="orgid" value="00D4W000005ikyp" />
                      <input type="hidden" name="retURL" value="https://www.omnidya.com/" />
                      <input type="hidden" name="type" value="Other Inquiry"/>
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input id="name" name="name" size="20" type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input id="email" name="email" type="text" className="form-control" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="description">Message Us</label>
                        <textarea name="description" id="description" className="form-control" rows="3" ></textarea>
                      </div>
                      <button type="submit" name="submit" className="btn btn-blue btn-block">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-page-bg">
        <div className="container-xxl">
          <div className="row justify-content-center">
            <div className="col col-md-8">
              <div className="mb-5">
                <Title title="We're here to help!" />
                <p>
                  For general queries, including partnership opportunities, please email info@omnidya.com
                </p>
              </div>
            </div>
          </div>
        </div>
        <JourneyOmnidya gutter />
      </div>
    </Layout>
  )
}

export default Contact
